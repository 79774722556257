import React, {useEffect, useRef, useState} from 'react';
import Layout from "../components/layout";
import {get} from "../helper/api";
import RevolutCheckout from "@revolut/checkout";
import {REVOLUT_ENV} from "../constants/consts";
import {navigate} from 'gatsby';

const IndexPage = ({id}) => {

    const [linkExpired, setLinkExpired] = useState(null);
    const [order, setOrder] = useState(null);
    const [cardErrors, setCardErrors] = useState([]);
    const [sum, setSum] = useState([]);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [inProgress, setInProgress] = useState(false);

    const rcRef = useRef(null);
    const cardElementRef = useRef(null);

    const fetchOrder = async () => {
        setInProgress(true);
        const orderData = await get(`/orders/${id}`);
        setLinkExpired(!orderData);
        setOrder(orderData);
        setInProgress(false);
    }

    useEffect(() => {
        fetchOrder();
    }, []);

    useEffect(() => {
        if (!order) return;
        setSum(new Intl.NumberFormat('en-GB',
            /*{style: 'currency', currency: order.currency}*/
        ).format(order.total / 100));
        rcRef.current = RevolutCheckout(order.token, REVOLUT_ENV).then(RC => {
            rcRef.current = RC.createCardField({
                target: cardElementRef.current,
                hidePostcodeField: true,
                styles: {
                    default: {
                        "::placeholder": {
                            color: "#5A666F"
                        }
                    }
                },
                onValidation(errors) {
                    setCardErrors(errors);
                    setInProgress(false);
                },
                onSuccess() {
                    setInProgress(false);
                    navigate('/thank-you');
                },
                onError(error) {
                    console.log(error);
                    console.log(JSON.stringify(error));
                    if (error?.type && ['validation.name.invalid', 'validation.email.invalid'].includes(error.type)) {
                        setCardErrors([{
                            type: error.type,
                            message: error.message
                        }]);
                        return;
                    }
                    alert("eror, check console")
                    setInProgress(false);
                },
                onCancel() {
                    //renewOrder(order.id);
                    setInProgress(false);
                }
            });
        })

        return () => {
            rcRef.current.destroy();
        };
    }, [order]);

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setInProgress(true);

        rcRef.current.submit({
            name,
            email,
            savePaymentMethodFor: "merchant"
        });
    }

    return (
        <Layout>
            <div className="wrapper w-full">
                {linkExpired !== null && <div>
                    <div
                        className="py-4 lg:py-16 px-1 lg:px-6 flex justify-center items-center 2xl:mx-auto 2xl:container">
                        {linkExpired ?
                            <h3 className={"w-full text-center"}>Invalid link</h3> :
                            <div className="flex flex-col justify-start items-start w-full space-y-9">
                                <div
                                    className="flex flex-col xl:flex-row justify-center xl:justify-between space-y-6 xl:space-x-6 w-full">
                                    <div
                                        className="flex flex-col lg:w-8/12 justify-center items-center">
                                        <div className="flex justify-start flex-col items-start mb-4">
                                            <h3 className="text-blue-51">Checkout Summary</h3>
                                        </div>
                                        <div className="bg-gray-200 w-full py-8 px-4 lg:px-10 shadow-lg">
                                            <div className={"lg:flex pb-4"}>
                                                <div className="lg:w-2/5 flex items-center lg:-ml-[80px]">
                                                    <img src={'/images/card-payment.png'}/>
                                                </div>
                                                <div className="lg:w-3/5 lg:ml-[80px] space-y-4 leading-normal text-gray-800">
                                                    <div className={"flex items-center"}>
                                                        <p className={"order-label"}>Name:</p>
                                                        <p className={"order-value w-full"}>{order?.name}</p>
                                                    </div>
                                                    <div className={"flex items-center"}>
                                                        <p className={"order-label"}>Email:</p>
                                                        <p className={"order-value w-full"}>{order?.email}</p>
                                                    </div>
                                                    <div className={"flex items-center"}>
                                                        <p className={"order-label"}>Recurring payment:</p>
                                                        <p className={"order-value"}>{order?.isRecurring ? order?.frequency : 'One time'}</p>
                                                    </div>
                                                    <div className={"flex items-center"}>
                                                        <p className={"order-label"}>Comment:</p>
                                                        <p className={"order-value w-full"}>{order?.comment}</p>
                                                    </div>
                                                    <div className={"flex items-center"}>
                                                        <p className={"order-label m-0"}>Amount:</p>
                                                        <div className={"order-amount"}>
                                                            <span>&#163;</span>
                                                            <p>{sum}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex items-center border-t-default w-full justify-center pt-4">
                                                <img src="/images/secure1.png"  style={{height: 28}}/>
                                                <p className={"mr-2 m-0"}>Secure Payments by</p>
                                                <img style={{height: 34}} src="https://cdn.brandfolder.io/E5GZRO5R/at/q7nhgm-5tjrm8-5gvp8a/Revolut_White_BG-B-P05.png?width=300&height=110"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-8 bg-blue-51 lg:w-4/12 flex flex-col rounded-default text-white">
                                        <h3 className={"font-montserrat text-center"}>Payment Details</h3>
                                        <p className="font-size-4 pt-2 text-red">
                                            {cardErrors.map(
                                                error =>
                                                    error.message
                                            )}
                                        </p>
                                        <div className="mt-2 flex-col">
                                            <div>
                                                <input
                                                    className="border rounded border-gray-300 p-4 w-full text-base leading-4 placeholder-gray-600 text-gray-600 rounded-lg"
                                                    type="text" name="name" id="name" autoComplete="name"
                                                    onChange={(event) => setName(event.target.value)}
                                                    placeholder="Name on card"/>
                                            </div>
                                        </div>

                                        <div className="mt-8">
                                            <input
                                                className="border border-gray-300 p-4 w-full text-base leading-4 placeholder-gray-600 text-gray-600 rounded-lg"
                                                type="email" name="email" id="email" autoComplete="email"
                                                onChange={(event) => setEmail(event.target.value)}
                                                placeholder="Email"/>
                                        </div>

                                        <label className="mt-8 text-base leading-4">Card details</label>
                                        <div className="mt-2 flex-col bg-white p-4 rounded-lg" ref={cardElementRef}/>

                                        <button
                                            className="mt-8 border border-transparent hover:border-gray-300 text-white flex justify-center items-center py-2 rounded-lg font-bold text-black"
                                            style={{background: "#F0C92F"}}
                                            onClick={handleFormSubmit}
                                            disabled={inProgress}>
                                            <div className="text-lg leading-4">Pay Now</div>
                                        </button>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>}
            </div>
        </Layout>
    )
}

export default IndexPage
